import React                              from 'react';
import { graphql, Link }                  from 'gatsby';
import Img                                from 'gatsby-image';
import { Flex, Box, Button, Center }      from '@chakra-ui/react';
import { GiDiamondRing, GiWatch, GiShop } from 'react-icons/gi';
import Breakpoint                         from 'react-socks';

import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Separator                 from '@interness/web-core/src/components/structure/Separator/Separator';
import SEO                       from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander              from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import RandomShopItems           from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import FramedImage               from '@interness/theme-sonora/src/components/FramedImage';
import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';


function IndexPage({ data }) {
  return (
    <>
      <SEO title={'Startseite'}/>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Wienken</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>
              <FramedImage>
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>
              <p>Herzlich willkommen bei Juwelier Wienken in Unna – Ihrem exklusiven Ansprechpartner für
                außergewöhnlichen Schmuck, feine Uhren und traumhafte Trauringe.</p>

              <p>Seit mehr als 60 Jahren sind wir stolz darauf, Ihnen als renommierter und traditioneller Juwelier in
                Unna ein ausgewähltes Sortiment an Schmuck und Uhren präsentieren zu können.</p>

              <p>Tauchen Sie ein in die funkelnde Welt von Schmuckstücken, die jeden Anlass perfekt unterstreichen und
                Ihren persönlichen Stil hervorheben.</p>

              <p>Unsere Partnerschaft mit bekannten Marken wie Tissot, Herbelin und Mido ermöglicht es uns, Ihnen ein
                Uhren von höchster Präzision und Qualität anzubieten.</p>
              <MoreExpander title="Noch ein paar Worte zu Juwelier Wienken:">
                <p>Der schönste Tag im Leben verdient den perfekten Ring. Lassen Sie sich von unseren einzigartigen
                  Designs inspirieren oder gestalten Sie gemeinsam mit uns Ihre individuellen Traumringe, die Ihre Liebe
                  symbolisieren.</p>

                <p>Vertrauen Sie auf unsere Meisterwerkstatt, wenn es um die Reparatur und Pflege Ihrer kostbaren
                  Schätze geht. Wir kümmern uns sorgfältig um Uhren, Schmuck und Perlen, damit sie ihren Glanz
                  bewahren</p>

                <p>Wir legen großen Wert auf individuelle und persönliche Beratung und ausgezeichneten Service durch
                  unser fachkundiges Team.
                  Erleben Sie die zeitlose Eleganz und lassen Sie sich von unserer Leidenschaft für eleganten Schmuck
                  und feine Uhren inspirieren. Wir freuen uns darauf, Sie persönlich bei Juwelier Wienken in Unna
                  begrüßen zu können.</p>

                <p>Bis bald, Ihre Familie Wienken</p>
              </MoreExpander>
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiWatch/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;