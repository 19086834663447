import { useStaticQuery, graphql } from 'gatsby';

export const useRandomBrandSquareGalleryImages = (count = 1, type = 'all', lowestBrandRating = 1) => {
  let brands = useStaticQuery(query).allDirectusBrands.nodes;
  if (type !== 'all') {
    brands = brands.filter(({ brand }) => brand.type.type === type);
  }
  if (lowestBrandRating > 1) {
    brands = brands.filter(({ brand }) => brand.brand_rating >= lowestBrandRating);
  }
  return brands.sort(() => .5 - Math.random()).slice(0, count);
};

const query = graphql`
    query {
        allDirectusBrands(filter: {brand: {status: {eq: "published"}}, id: {ne: "dummy"}}) {
            nodes {
                brand {
                    display_name
                    slug
                    brand_rating
                    type {
                        type
                    }
                    gallery_images_square {
                        file {
                            width
                            height
                            localFile {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 700, maxHeight: 700, quality: 95) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;